import axios from 'axios'

const state = {
  vehicles: []
}

const getters = {
  vehicleIds: (state) => state.vehicles.map(s => s.id),
  getVehicleById: (state) => (id) => {
    return state.vehicles.find((vehicle) => { return vehicle.id === id })
  }
}

const actions = {
  /**
   * Fetches all vehicles the current user has access to.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Promise} The response of the server.
   */
  fetchAllVehicles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('vehicles')
        .then(response => {
          commit('setVehicles', response.data.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * Fetches all vehicles that fuelled at a a specific system.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {*} systemId The id of the system to fetch vehicles for.
   * @returns {Object} The axios response.
   */
  async fetchVehiclesBySystem ({ commit }, systemId) {
    const response = await axios.get(`systems/${systemId}/vehicles`)
    commit('addOrUpdateVehicles', response.data.items)
    // Returning response for situations where the caller would like to specifically display/use the vehicles for this system.
    return response
  },
  /**
   * Fetches vehicles sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchVehicles ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('vehicles', { params: { pageNumber: pageNumber, pageSize: pageSize, sorting: sorting.toQueryParams() } })
    return response.data
  }
}

const mutations = {
  setVehicles: (state, vehicles) => (state.vehicles = vehicles),
  addOrUpdateVehicles: (state, newVehicles) => {
    if (state.vehicles.length === 0) {
      state.vehicles = newVehicles
      return
    }

    for (let i = 0; i < newVehicles.length; i++) {
      const existingIndex = state.vehicles.findIndex((existingVehicle) => existingVehicle.id === newVehicles[i].id)
      if (existingIndex >= 0) {
        // Replace the existing vehicle.
        state.vehicles[existingIndex] = newVehicles[i]
      } else {
        // Add the vehicle.
        state.vehicles.push(newVehicles[i])
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
