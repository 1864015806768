import axios from 'axios'

const state = {}

const getters = {}

const actions = {
  /**
   * Fetches configuration backups by system id sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.systemId
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchConfigurationBackupsBySystemId ({ commit }, { systemId, pageNumber, pageSize, sorting }) {
    const response = await axios.get(`systems/${systemId}/configurationBackups`, {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        sorting: sorting.toQueryParams()
      }
    })
    return response.data
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
