import axios from 'axios'

const state = {
  projects: []
}

const getters = {
  getProjectById: (state) => (id) => {
    return state.projects.find((project) => project.id === id)
  },
  getProjectsByIds: (state) => (ids) => {
    return state.projects.filter((project) => { return ids.includes(project.id) })
  }
}

const actions = {
  /**
  * Fetches all projects. This should only be called for admin screens.
  * @param {*} param0 vuex object, does not have to be provided by the consumer.
  * @returns {Object} The (axios) response of the server.
  */
  async fetchAllProjects ({ commit }) {
    // NOTE: this will only fetch a certain amount of projects (100 by default), because it is paged.
    const response = await axios.get('projects')
    commit('setProjects', response.data.items)
    return response
  },
  /**
   * Fetches projects sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchProjects ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('projects', { params: { pageNumber: pageNumber, pageSize: pageSize, sorting: sorting.toQueryParams() } })
    return response.data
  }
}

const mutations = {
  setProjects: (state, projects) => (state.projects = projects)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
