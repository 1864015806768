function defaultSessionFilter () {
  return {
    projectIds: [],
    systemIds: [],
    softwareVersionIds: [],
    vehicleIds: [],
    driverIds: [],
    errorCauseIds: [],
    errors: [],
    from: null,
    to: null,
    includeSuccessful: true,
    includeArchive: false
  }
}

const storageKey = 'session-filter'

function updateStorage (newValue) {
  localStorage.setItem(storageKey, JSON.stringify(newValue))
}

// If the session filter is not yet in the local storage...
if (!localStorage.getItem(storageKey)) {
  // Save the default values.
  updateStorage(defaultSessionFilter())
}

const state = {
  sessionFilter: JSON.parse(localStorage.getItem(storageKey))
}

const getters = {}

const actions = {
  resetSessionFilter ({ commit }) {
    commit('setSessionFilter', defaultSessionFilter())
  }
}

const mutations = {
  setSessionFilter: (state, sessionFilter) => {
    state.sessionFilter = sessionFilter
    updateStorage(sessionFilter)
  },
  setSessionFilterProperty: (state, { propertyName, newValue }) => {
    state.sessionFilter[propertyName] = newValue
    updateStorage(state.sessionFilter)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
