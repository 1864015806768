<template>
  <div>
    <ChangeLanguageDialog
      v-model="showLanguageDialog"
      @input="(newValue) => { showLanguageDialog = newValue }"
    />

    <!-- The 'shift' stuff means only the active route button will have text on extra small screens -->
    <v-bottom-navigation app fixed color="primary" grow :shift="!$vuetify.breakpoint.smAndUp">
      <v-img
        contain
        src="rotec-logo-no-text.png"
        height="inherit"
        width="30px"
        class="bottom-navigation-logo"
        max-width="50px"
      />

      <v-divider vertical />

      <!-- Customer/visitor links -->
      <v-btn
        v-for="item in menuItems"
        :key="item.title"
        :to="item.path"
      >
        <span>{{ $t(item.title) }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>

      <!-- Link to user settings -->
      <v-btn v-if="isLoggedIn" :to="'/settings'">
        <span>Account</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <!-- Admin links -->
      <v-divider v-if="isAdmin" vertical />
      <v-menu v-if="isAdmin" top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            <span>Admin</span>
            <v-icon>mdi-form-select</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in adminMenuItems"
            :key="item.title"
            :to="item.path"
            active-class="primary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Language switcher -->
      <v-list-item
        @click="showLanguageDialog = true"
        class="d-flex justify-center"
      >
          <gb-flag :code="currentLanguage.flag" width="30px" height="30px"/>
      </v-list-item>

    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ChangeLanguageDialog from '@/components/dialogs/ChangeLanguageDialog'

export default {
  components: { ChangeLanguageDialog },
  data () {
    return {
      showLanguageDialog: false
    }
  },
  computed: {
    ...mapGetters('languages', ['currentLanguage']),
    ...mapState('menuItems', ['menuItems', 'adminMenuItems']),
    ...mapGetters('account', ['roles', 'isAdmin']),
    isLoggedIn () {
      return this.roles.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
// Workaround to make sure buttons in bottom navigation are sized correctly (https://github.com/vuetifyjs/vuetify/issues/8067#issuecomment-534848678).
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
    height: inherit;
}

// On smaller screen sizes, the bottom navigation must have a horizontal scroll bar in order to be able to see all items.
.v-bottom-navigation {
  // This needs to be important or vuetify will override it with 'center'
  justify-content: start !important;
  overflow-x: auto;
  overflow-y: hidden;
}

// Give the logo a bit of space, so it won't be cramped against the buttons in the bottom navigation bar.
.bottom-navigation-logo {
  margin-left: 4%;
  margin-right: 4%;
}
</style>
