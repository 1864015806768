import axios from 'axios'

const state = {
  systems: []
}

const getters = {
  systemIds: (state) => state.systems.map(s => s.id),
  getSystemById: (state) => (id) => {
    return state.systems.find(system => system.id === id)
  },
  getSystemsByIds: (state) => (ids) => {
    return state.systems.filter(system => ids.includes(system.id))
  }
}

const actions = {
  /**
   * Fetches a specific system by id.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {*} id The id of the system.
   * @returns {Promise} The response of the server.
   */
  async fetchSystem ({ commit }, id) {
    const response = await axios.get(`systems/${id}`)
    commit('setSystem', response.data)
    return response
  },
  /**
   * Fetches all systems the current user has access to.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Promise} The response of the server.
   */
  async fetchAllSystems ({ commit }) {
    // NOTE: this will only fetch a certain amount of systems (100 by default), because it is paged.
    const response = await axios.get('systems')
    commit('setSystems', response.data.items)
    return response
  },
  /**
   * Fetches systems sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchSystems ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('systems', { params: { pageNumber: pageNumber, pageSize: pageSize, sorting: sorting.toQueryParams() } })
    return response.data
  },
  /**
   * Fetches all systems for the specified user group ids.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Array<number>} userGroupIds The ids of the user groups.
   * @returns {Promise} The response of the server.
   */
  fetchSystemsByUserGroupIds ({ commit }, userGroupIds) {
    return new Promise((resolve, reject) => {
      if (userGroupIds.length === 0) {
        commit('setSystems', [])
        resolve()
      }

      // Create a request for every user group.
      const requests = userGroupIds.map((id) => axios.get(`userGroups/${id}/systems`))

      // Wait for responses to all requests.
      axios
        .all(requests)
        .then(axios.spread((...responses) => {
          // Combine the results of all requests.
          const systems = responses.reduce(function (accumulator, response) {
            return accumulator.concat(response.data)
          }, [])

          // Save the systems.
          commit('setSystems', systems)
          resolve(responses)
        }))
        .catch(axios.spread((...errors) => {
          reject(errors[0])
        }))
    })
  }
}

const mutations = {
  setSystems: (state, systems) => (state.systems = systems),
  setSystem (state, newSystem) {
    const existingIndex = state.systems.findIndex(system => system.id === newSystem.id)

    if (existingIndex >= 0) state.systems[existingIndex] = newSystem
    else state.systems.push(newSystem)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
