<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title class="headline">
        <span>{{ $t('languages.dialog.title') }}</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          clearable
          autofocus
          v-model="searchText"
        />
        <v-list class="pt-0">
            <v-list-item
              v-for="language in filteredLanguages"
              :key="language.code"
              @click="languageSelected(language.code)"
              :class="{'primary--text v-item--active v-list-item--active': language.code === currentLanguageCode}"
            >
              <v-list-item-icon>
                <gb-flag :code="language.flag" size="large"/>
              </v-list-item-icon>
              <v-list-item-title>
                {{ language.displayName }}
              </v-list-item-title>
            </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="showDialog = false"
        >
          {{ $t('common.buttons.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true }
  },
  data () {
    return {
      searchText: ''
    }
  },
  computed: {
    ...mapState('languages', ['languages', 'currentLanguageCode']),
    ...mapGetters('languages', ['searchLanguages']),
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    filteredLanguages () {
      const result = this.searchLanguages(this.searchText)
      return result
    }
  },
  methods: {
    ...mapActions('languages', ['changeLanguage']),
    languageSelected (code) {
      this.changeLanguage({ newLanguageCode: code, vuetify: this.$vuetify })
    }
  }
}
</script>
