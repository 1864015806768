import axios from 'axios'

const state = {
  softwareVersions: []
}

const getters = {
  getSoftwareVersionById: (state) => (id) => {
    const result = state.softwareVersions.filter((sv) => { return sv.id === id })
    if (result.length === 0) {
      return null
    } else {
      return result[0]
    }
  }
}

const actions = {
  /**
   * Fetches all software versions.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Promise} The response of the server.
   */
  fetchSoftwareVersions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('softwareVersions')
        .then(response => {
          commit('setSoftwareVersions', response.data.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * Fetches all software versions of a specific system.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {*} systemId The id of the system to fetch software versions for.
   * @returns {Object} The axios response.
   */
  async fetchSoftwareVersionsBySystem ({ commit }, systemId) {
    const response = await axios.get(`systems/${systemId}/softwareVersions`)
    commit('addOrUpdateSoftwareVersions', response.data.items)
    // Returning response for situations where the caller would like to specifically display/use the software versions for this system.
    return response
  }
}

const mutations = {
  setSoftwareVersions: (state, softwareVersions) => (state.softwareVersions = softwareVersions),
  addOrUpdateDrivers: (state, newSoftwareVersions) => {
    if (state.softwareVersions.length === 0) {
      state.softwareVersions = newSoftwareVersions
      return
    }

    for (let i = 0; i < newSoftwareVersions.length; i++) {
      const existingIndex = state.softwareVersions.findIndex((existingSoftwareVersion) => existingSoftwareVersion.id === newSoftwareVersions[i].id)
      if (existingIndex >= 0) {
        // Replace the existing softwareVersion.
        state.softwareVersions[existingIndex] = newSoftwareVersions[i]
      } else {
        // Add the softwareVersion.
        state.softwareVersions.push(newSoftwareVersions[i])
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
