import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// NOTE: it's probably not that pretty to import all languages, but they are very small files.
import en from 'vuetify/es5/locale/en'
import nl from 'vuetify/es5/locale/nl'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: localStorage.getItem('darkMode') === 'true'
  },
  lang: {
    locales: { en, nl, es },
    current: 'en'
  }
})
