<template>
  <SnackbarNotification
    v-model="snackBarVisible"
    :role="params.role"
    :messages="params.messages"
    :timeout="params.duration"
    :showCloseButton="params.showCloseButton"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SnackbarNotification from '@/components/SnackbarNotification'

export default {
  components: { SnackbarNotification },
  computed: {
    ...mapState('snackbar', ['show', 'params']),
    /**
     * We had to wrap the snackbar.show state in a separate property to accomodate the setter.
     * @returns {Boolean} Whether to show the snackbar.
     */
    snackBarVisible: {
      get () {
        return this.show
      },
      set (newValue) {
        if (newValue) this.showSnackbar(this.params)
        else this.hideSnackbar()
      }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar', 'hideSnackbar'])
  }
}
</script>
