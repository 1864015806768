<template>
  <v-snackbar v-model="showSnackbar"
              :timeout="timeout"
              top
              :dark="darkMode"
              :light="!darkMode"
              >
    <v-row class="align-center">
      <v-col cols="2">
        <v-icon :color="iconColor"
                class="notification-icon"
                large>
          {{ icon }}
        </v-icon>
      </v-col>

      <v-col cols="10">
        <span v-if="messages.length === 1" :class="textClass">
          {{ messages[0] }}
        </span>

        <ul v-if="messages.length > 1">
          <li v-for="message in messages" :key="message" :class="textClass">
            {{message}}
          </li>
        </ul>
      </v-col>
    </v-row>

    <template v-slot:action="{ attrs }">
      <v-btn v-if="showCloseButton"
             :color="closeButtonColor"
             icon
             v-bind="attrs"
             @click="showSnackbar = false">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this snackbar should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    messages: { type: Array, required: true },
    /**
     * The role of the notifcation message(s).
     * Choose between info, warn, success and error.
     */
    role: { type: String, default: 'info' },
    /**
     * Timeout (ms) before the snackbar disappears, -1 means indefinitely.
     */
    timeout: { type: Number, default: -1 },
    showCloseButton: { type: Boolean, default: true }
  },
  data () {
    return {
      colors: {
        info: 'info',
        warn: 'warning',
        error: 'error',
        success: 'success'
      },
      icons: {
        info: 'mdi-information',
        warn: 'mdi-alert',
        error: 'mdi-alert-circle',
        success: 'mdi-check-bold'
      },
      textClasses: {
        info: 'info--text',
        warn: 'warning--text',
        error: 'error--text',
        success: 'success--text'
      }
    }
  },
  computed: {
    ...mapState('preferences', ['darkMode']),
    showSnackbar: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    closeButtonColor () {
      return this.colors[this.role]
    },
    icon () {
      return this.icons[this.role]
    },
    iconColor () {
      return this.colors[this.role]
    },
    textClass () {
      return this.textClasses[this.role]
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-icon {
  vertical-align: middle
}
</style>
