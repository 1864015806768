import axios from 'axios'

const state = {
  allErrors: []
}

const getters = {}

const actions = {
  /**
  * Fetches all errors.
  * @param {*} param0 vuex object, does not have to be provided by the consumer.
  * @returns {Object} The (axios) response of the server.
  */
  async fetchAllErrors ({ commit }) {
    const response = await axios.get('sessionEvents/errors')
    commit('setErrors', response.data)
    return response
  }
}

const mutations = {
  setErrors: (state, errors) => (state.allErrors = errors)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
