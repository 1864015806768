import Vue from 'vue'
import Vuex from 'vuex'
import account from '@/store/modules/account'
import claims from '@/store/modules/claims'
import configurationBackups from '@/store/modules/configurationBackups'
import drivers from '@/store/modules/drivers'
import errorCauses from '@/store/modules/errorCauses'
import errors from '@/store/modules/errors'
import fileContainers from '@/store/modules/fileContainers'
import files from '@/store/modules/files'
import fuelTypes from '@/store/modules/fuelTypes'
import languages from '@/store/modules/languages'
import menuItems from '@/store/modules/menuItems'
import preferences from '@/store/modules/preferences'
import projects from '@/store/modules/projects'
import roles from '@/store/modules/roles'
import sessionFilter from '@/store/modules/sessionFilter'
import sessions from '@/store/modules/sessions'
import snackbar from '@/store/modules/snackbar'
import softwareVersions from '@/store/modules/softwareVersions'
import suspensions from '@/store/modules/suspensions'
import systems from '@/store/modules/systems'
import timezones from '@/store/modules/timezones'
import userGroups from '@/store/modules/userGroups'
import users from '@/store/modules/users'
import vehicles from '@/store/modules/vehicles'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account: account,
    claims: claims,
    configurationBackups: configurationBackups,
    drivers: drivers,
    errorCauses: errorCauses,
    errors: errors,
    fileContainers: fileContainers,
    files: files,
    fuelTypes: fuelTypes,
    languages: languages,
    menuItems: menuItems,
    preferences: preferences,
    projects: projects,
    roles: roles,
    sessionFilter: sessionFilter,
    sessions: sessions,
    snackbar: snackbar,
    softwareVersions: softwareVersions,
    suspensions: suspensions,
    systems: systems,
    timezones: timezones,
    userGroups: userGroups,
    users: users,
    vehicles: vehicles
  }
})
