import axios from 'axios'
import moment from 'moment-timezone'

const prefersBottomNavigationKey = 'prefersBottomNavigation'
const darkModeKey = 'darkMode'
const showAdvancedChartControlsKey = 'showAdvancedChartControls'
const timezoneKey = 'timezone'

const state = {
  prefersBottomNavigation: localStorage.getItem(prefersBottomNavigationKey) === 'true',
  darkMode: localStorage.getItem(darkModeKey) === 'true',
  showAdvancedChartControls: localStorage.getItem(showAdvancedChartControlsKey) === 'true',
  timezone: localStorage.getItem(timezoneKey) || moment.tz.guess()
}

const getters = {}

const actions = {
  /**
   * Toggles to dark or light mode.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} param1 The object containing the new boolean and the vuetify accessor.
   */
  changeDarkMode ({ commit }, { darkMode, vuetify }) {
    vuetify.theme.dark = darkMode
    localStorage.setItem(darkModeKey, darkMode)
    commit('setDarkMode', darkMode)
  },
  /**
   * Changes the user's preference for bottom navigation.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Boolean} prefersBottomNavigation True if the user prefers the bottom navigation, false if otherwise.
   */
  changeBottomNavigationPreference ({ commit }, prefersBottomNavigation) {
    localStorage.setItem(prefersBottomNavigationKey, prefersBottomNavigation)
    commit('setPrefersBottomNavigation', prefersBottomNavigation)
  },
  /**
   * Changes the user's preference for advanced chart controls.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Boolean} showAdvancedChartControls True if the user prefers advanced chart controls, false if otherwise.
   */
  changeShowAdvancedChartControls ({ commit }, showAdvancedChartControls) {
    localStorage.setItem(showAdvancedChartControlsKey, showAdvancedChartControls)
    commit('setShowAdvancedChartControls', showAdvancedChartControls)
  },
  changeTimezone ({ state, commit }, timezone) {
    // Reset the timezone if an incorrect timezone is given (null, '' or any other falsy value).
    timezone = timezone || state.timezone
    localStorage.setItem(timezoneKey, timezone)
    moment.tz.setDefault(timezone)
    axios.defaults.headers['Rotec-Dashboard-Timezone'] = timezone
    commit('setTimezone', timezone)
  }
}

const mutations = {
  setDarkMode: (state, useDarkMode) => (state.darkMode = useDarkMode),
  setPrefersBottomNavigation: (state, prefersBottomNavigation) => (state.prefersBottomNavigation = prefersBottomNavigation),
  setShowAdvancedChartControls: (state, showAdvancedChartControls) => (state.showAdvancedChartControls = showAdvancedChartControls),
  setTimezone: (state, timezone) => (state.timezone = timezone)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
