import axios from 'axios'
import moment from 'moment-timezone'
import { languages, languageIsSupported, setLanguageAsync } from '@/i18n'

const languageKey = 'preferredLanguage'

/**
 * Gets the language code that is appropriate for the current user.
 * @returns A language code like 'en' or 'nl'.
 */
function getLanguageCode () {
  // Try to get the language which was selected by the user in this app.
  const languageInStore = localStorage.getItem(languageKey)
  if (languageIsSupported(languageInStore)) return languageInStore

  // Try to get the language of the browser.
  if (navigator.language) {
    // Exclude the country code, for example: 'en-gb' -> 'en'.
    const navigatorLanguage = navigator.language.split('-')[0]
    if (languageIsSupported(navigatorLanguage)) return navigatorLanguage
  }

  // Get any of the default language codes.
  return process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE
}

const state = {
  currentLanguageCode: getLanguageCode()
}

const getters = {
  currentLanguage (state) {
    return languages.find((language) => language.code.toLowerCase() === state.currentLanguageCode.toLowerCase())
  },
  searchLanguages: (state) => (query) => {
    if (!query) {
      return languages
    }

    const cleanQuery = query
      // Ignore case.
      .toLowerCase()
      // Normalizes accents, etc. to be the same value.
      .normalize()
      // Ignore any whitespace characters
      .replace(/\s+/g, '')

    if (cleanQuery.length === 0) {
      return languages
    }

    return languages.filter((language) => {
      return language.code.toLowerCase().includes(cleanQuery) ||
        language.searchTags.some((tag) => tag.toLowerCase().includes(cleanQuery))
    })
  }
}

const actions = {
  /**
   * Changes to a new language.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} param1 The object containing the new language code and the vuetify acessor.
   */
  async changeLanguage ({ commit }, { newLanguageCode, vuetify }) {
    await setLanguageAsync(newLanguageCode)
    // We have to change the vuetify language here, because we can't do that in the i18n file.
    vuetify.lang.current = newLanguageCode
    localStorage.setItem(languageKey, newLanguageCode)
    axios.defaults.headers['Rotec-Dashboard-Locale'] = newLanguageCode
    document.querySelector('html').setAttribute('lang', newLanguageCode)
    moment.locale(newLanguageCode)
    commit('setCurrentLanguageCode', newLanguageCode)
  }
}

const mutations = {
  setCurrentLanguageCode: (state, newLanguageCode) => (state.currentLanguageCode = newLanguageCode)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
