import axios from 'axios'

const state = {
  roles: []
}

const getters = {
  getRolesByIds: (state) => (ids) => {
    return state.roles.filter((role) => { return ids.includes(role.id) })
  }
}

const actions = {
  /**
  * Fetches all roles.
  * @param {*} param0 vuex object, does not have to be provided by the consumer.
  * @returns {Object} The (axios) response of the server.
  */
  async fetchRoles ({ commit }) {
    const response = await axios.get('roles')
    commit('setRoles', response.data.items)
    return response
  }
}

const mutations = {
  setRoles: (state, roles) => (state.roles = roles)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
