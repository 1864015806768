const clockSkewSeconds = 5

export function getRolesFromDecodedToken (decodedToken) {
  if (!decodedToken || !decodedToken.role) return []

  // If it is a string, we should return it as an array of string.
  // If it is not a string, it will be an array.
  return typeof decodedToken.role === 'string' ? [decodedToken.role] : decodedToken.role
}

export function getPropertyFromDecodedToken (decodedToken, propertyName) {
  if (!decodedToken || !decodedToken[propertyName]) return null

  return decodedToken[propertyName]
}

export function getUserGroupsFromDecodedToken (decodedToken) {
  if (!decodedToken || !decodedToken.usergroups) return []

  // If it is a string, we should return it as an array with a single number.
  // If it is not a string, it will be an array.
  return typeof decodedToken.usergroups === 'string'
    ? [parseInt(decodedToken.usergroups)]
    : decodedToken.usergroups.map((item) => parseInt(item))
}

/**
 * Calculates the time until the provided token is expired.
 * @param {Object} decodedToken A jwt token decoded into an object.
 * @returns {Number} Amount of milliseconds until the token is expired.
 */
export function getTimeUntilExpiryFromDecodedToken (decodedToken) {
  if (!decodedToken || !decodedToken.exp) return null

  // Adding some clockskew to prevent issues with clock differences between server and client.
  const expiryDate = (decodedToken.exp + clockSkewSeconds) * 1000
  const currentTime = new Date().getTime()

  return expiryDate - currentTime
}
