const ErrorEnum = {
  // Anything between 1000 and 10000, is an error coming from the api.

  // Anything 10000 or higher is an internal error in the webapp or an error based on status codes.
  LOGIN_UNKNOWN_FAILURE: 10001,
  REFRESH_LOGIN_UNKNOWN_FAILURE: 10002,
  PASSWORD_CHANGE_UNKNOWN_FAILURE: 10003,
  TOO_MANY_REQUESTS: 10004,

  LOADING_USERGROUPS_FAILED: 11000,
  LOADING_DATA_FAILED: 11001
}
Object.freeze(ErrorEnum)

export {
  ErrorEnum
}
