import axios from 'axios'

const state = {
  claims: []
}

const getters = {}

const actions = {
  /**
  * Fetches all claims.
  * @param {*} param0 vuex object, does not have to be provided by the consumer.
  * @returns {Object} The (axios) response of the server.
  */
  async fetchClaims ({ commit }) {
    const response = await axios.get('claims')
    commit('setClaims', response.data)
    return response
  }
}

const mutations = {
  setClaims: (state, claims) => (state.claims = claims)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
