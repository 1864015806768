import axios from 'axios'

const state = {
  systemUsers: []
}

const getters = {}

const actions = {
  /**
   * Fetches a specific user by its id.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Number} userId The id of the user that needs to be fetched.
   * @returns {Object} The user, if found.
   */
  async fetchUserById ({ commit }, userId) {
    const response = await axios.get(`users/${userId}`)
    return response.data
  },
  /**
   * Fetches users sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchUsers ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('users', { params: { pageNumber: pageNumber, pageSize: pageSize, sorting: sorting.toQueryParams() } })
    return response.data
  },
  /**
   * Fetches all users with the system role.
   * Also fill the state.systemUsers.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Object} The (axios) response of the server.
   */
  async fetchSystemUsers ({ commit }) {
    const rolesResponse = await axios.get('roles')
    const systemRole = rolesResponse.data.items.find(role => role.name.toLowerCase() === 'system')

    const response = await axios.get(`roles/${systemRole.id}/users`)
    commit('setSystemUsers', response.data.items)
    return response
  }
}

const mutations = {
  setSystemUsers: (state, systemUsers) => (state.systemUsers = systemUsers)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
