import axios from 'axios'

const state = {
  errorCauses: []
}

const getters = {
  getErrorCauseById: (state) => (id) => {
    return state.errorCauses.find((errorCause) => { return errorCause.id === id })
  }
}

const actions = {
  /**
   * Fetches all error causes that are assignable to a specific system.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {*} systemId The id of the system to fetch for.
   * @returns {Object} The axios response.
   */
  async fetchErrorCausesAssignableToSystem ({ commit }, systemId) {
    return await axios.get(`systems/${systemId}/assignableErrorCauses`)
  },
  /**
  * Fetches all error causes for the current user.
  * @param {*} param0 vuex object, does not have to be provided by the consumer.
  * @returns {Object} The (axios) response of the server.
  */
  async fetchAllErrorCauses ({ commit }) {
    // NOTE: this will only fetch 100 error causes, because it is paged.
    // I don't expect we will ever have 100 error causes though.
    const response = await axios.get('errorCauses', { params: { pageNumber: 1, pageSize: 100 } })
    commit('setErrorCauses', response.data.items)
    return response
  },
  /**
   * Fetches error causes sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchErrorCauses ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('errorcauses', { params: { pageNumber: pageNumber, pageSize: pageSize, sorting: sorting.toQueryParams() } })
    return response.data
  }
}

const mutations = {
  setErrorCauses: (state, errorCauses) => (state.errorCauses = errorCauses)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
