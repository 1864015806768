const defaultVisitorMenuItems = [
  { title: 'navigation.login', path: '/login', icon: 'mdi-login' },
  { title: 'navigation.about', path: '/about', icon: 'mdi-information-outline' }
]

const defaultLoggedInMenuItems = [
  { title: 'navigation.dashboard', path: '/', icon: 'mdi-view-dashboard' },
  { title: 'navigation.systems', path: '/systems', icon: 'mdi-laptop' },
  { title: 'navigation.sessions', path: '/sessions', icon: 'mdi-gas-station' },
  { title: 'navigation.vehicles', path: '/vehicles', icon: 'mdi-dump-truck' },
  { title: 'navigation.about', path: '/about', icon: 'mdi-information-outline' }
]

const defaultAdminMenuItems = [
  { title: 'navigation.projects', path: '/admin/projects', icon: 'mdi-group' },
  { title: 'navigation.systems', path: '/admin/systems', icon: 'mdi-laptop' },
  { title: 'navigation.usergroups', path: '/admin/usergroups', icon: 'mdi-account-group' },
  { title: 'navigation.users', path: '/admin/users', icon: 'mdi-account-details' },
  { title: 'navigation.error-causes', path: '/admin/errorcauses', icon: 'mdi-layers-remove' },
  { title: 'navigation.files', path: '/admin/files', icon: 'mdi-file' },
  { title: 'navigation.file-containers', path: '/admin/filecontainers', icon: 'mdi-folder-open' }
]

const state = {
  menuItems: defaultVisitorMenuItems,
  adminMenuItems: []
}

const getters = {}

const actions = {
  /**
   * Updates the menu to the roles of the current user.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {String} roles The current roles of the user. Supply with an empty array if the user is not logged in.
   */
  updateMenuItemsToRoles ({ commit }, roles) {
    const menuItems = roles.length > 0 ? defaultLoggedInMenuItems : defaultVisitorMenuItems
    const isAdmin = roles.includes('Admin') || roles.includes('Overlord')
    const adminMenuItems = isAdmin ? defaultAdminMenuItems : []

    commit('setMenuItems', menuItems)
    commit('setAdminMenuItems', adminMenuItems)
  }
}

const mutations = {
  setMenuItems: (state, menuItems) => (state.menuItems = menuItems),
  setAdminMenuItems: (state, menuItems) => (state.adminMenuItems = menuItems)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
