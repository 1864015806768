export default class Sorting {
  /**
   * Creates a sorting instance
   * @param {Array<string>} sortBy The columns/properties to sort. Example: ['name', 'age'].
   * @param {Array<bool>} sortDesc Whether to sort the respective columns descending. Example: [true, false].
   */
  constructor (sortBy, sortDesc) {
    this.sortBy = sortBy
    this.sortDesc = sortDesc
  }

  /**
   * Creates url-friendly query parameters for this sorting.
   * @returns {string} The query parameters. Example: 'name-desc,age-asc'.
   */
  toQueryParams () {
    // Maps (sortBy: ['name', 'age']) and (sortDesc: [true, false]) to:
    // 'name-desc,age-asc'
    return this.sortBy
      .map((column, i) => {
        const descending = this.sortDesc[i]
        return `${column}-${descending ? 'desc' : 'asc'}`
      })
      .join(',')
  }
}
