import timezones from 'google-timezones-json'

function GetTimezonesFromGoogle () {
  return Object.entries(timezones)
    .map(([label, content]) => {
      // Grabs the offset: '-09:00'.
      const offset = content.substring(4, content.indexOf(')'))

      // Calculate the total offset in minutes: -540.
      const multiplier = offset.startsWith('+') ? 1 : -1
      const hours = parseInt(offset.substring(1, 3))
      const minutes = parseInt(offset.substring(4, 6))
      const totalOffset = (minutes + hours * 60) * multiplier

      return {
        name: label,
        displayName: content,
        // Note: this is only a default offset and does not reflect DST changes.
        offsetMinutes: totalOffset
      }
    })
    .sort((a, b) => {
      // First sort by offset ASC.
      if (a.offsetMinutes > b.offsetMinutes) return 1
      if (a.offsetMinutes < b.offsetMinutes) return -1
      // Then sort by name ASC.
      return a.name.localeCompare(b.name)
    })
}

const state = {
  timezones: GetTimezonesFromGoogle()
}

const getters = {
  getTimeZoneByName: (state) => (zoneName) => {
    return state.timezones.find(zone => zone.name === zoneName)
  }
}

const actions = {
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
