import axios from 'axios'

const refreshLoginUrl = 'refreshLogin'

const excludedUrlsFromRefresh = [
  'login',
  'logout'
]

function setup (store, router) {
  // Skip everything if axios is already set up.
  if (axios.defaults.baseURL) {
    return
  }

  // Setup axios base url.
  axios.defaults.baseURL = process.env.VUE_APP_API_URL

  // Make sure the existing token is sent with every future request.
  const authToken = store.state.account.authenticationToken
  if (authToken) {
    axios.defaults.headers.Authorization = `Bearer ${authToken}`
  }

  // Make sure the accepted language is sent with every future request.
  axios.defaults.headers['Rotec-Dashboard-Locale'] = store.state.languages.currentLanguageCode
  // Make sure the timezone is sent with every future request.
  axios.defaults.headers['Rotec-Dashboard-Timezone'] = store.state.preferences.timezone

  // Interceptor for logging all requests.
  axios.interceptors.request.use(request => {
    const retry = request.isRetryAttempt ? ' (retry)' : ''
    console.log(`Request: ${request.method.toLocaleUpperCase()} ${request.url}${retry}`)
    return request
  }, error => {
    return Promise.reject(error)
  })

  // Interceptor for automagically refreshing the authentication token.
  axios.interceptors.response.use(
    null,
    async error => {
      const { config: originalReq, response } = error

      if (!response) {
        console.log('1. Wont refresh without a response')
        throw error
      }

      if (response.status !== 401) {
        console.log('2. Wont refresh because error is not 401')
        throw error
      }

      if (originalReq.isRetryAttempt) {
        console.log('3. Wont refresh because this is a retry attempt')
        throw error
      }

      // Obviously if the request to refresh the token fails, we won't try to refresh the token again.
      if (originalReq.url.toLowerCase().includes(refreshLoginUrl)) {
        // TODO: redirect to login again. (only if we're not yet on that page).
        // router.push({ name: 'Login' })
        console.log('4. Wont refresh refreshtoken requests')
        throw error
      }

      // Logging in/out doesn't care about remaining authenticated.
      const isExcludedUrl = excludedUrlsFromRefresh.some(url => originalReq.url.toLowerCase().includes(url.toLowerCase()))
      if (isExcludedUrl) {
        console.log('5. Wont refresh because this is login/logout')
        throw error
      }

      try {
        await store.dispatch('account/refreshLogin')
        originalReq.isRetryAttempt = true
        originalReq.headers.Authorization = axios.defaults.headers.Authorization
        return await axios.request(originalReq)
      } catch (e) {
        if (e.response && (e.response.status === 401)) {
          // Log user out and redirect to login login page on persistent 401 errors from original requests.
          console.log('6. Repeated 401 error (logging out and redirecting to login page)')
          store.dispatch('account/logout')
          router.push({ name: 'Login' })
        }
        // suppress original error to throw the new one to get new information
        throw e
      }
    }
  )
}

export default {
  setup: setup
}
