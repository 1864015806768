import axios from 'axios'
import moment from 'moment-timezone'

function applyLocalTimes (suspensions, timezone) {
  for (let i = 0; i < suspensions.length; i++) {
    applyLocalTime(suspensions[i], timezone)
  }
}

function applyLocalTime (suspension, timezone) {
  if (!suspension.until) return

  suspension.until = moment
    // Assume given date is utc.
    .utc(suspension.until)
    // Convert it to the preferred timezone.
    .tz(timezone)
    // Parse back to a string.
    .format()
}

const state = {}

const getters = {}

const actions = {
  /**
   * Fetches all suspensions for a specific user.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} parameters
   * @param {Number} parameters.userId
   * @param {Sorting} parameters.sorting
   * @returns {Array<Object>} The suspensions.
   */
  async fetchSuspensionsByUserId ({ commit, rootState }, { userId, sorting }) {
    const response = await axios.get(`users/${userId}/suspensions`, { params: { sorting: sorting.toQueryParams() } })
    applyLocalTimes(response.data, rootState.preferences.timezone)
    return response.data
  },
  /**
   * Fetches all suspensions for a specific usergroup.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} parameters
   * @param {Number} parameters.userGroupId
   * @param {Sorting} parameters.sorting
   * @returns {Array<Object>} The suspensions.
   */
  async fetchSuspensionsByUserGroupId ({ commit, rootState }, { userGroupId, sorting }) {
    const response = await axios.get(`usergroups/${userGroupId}/suspensions`, { params: { sorting: sorting.toQueryParams() } })
    applyLocalTimes(response.data, rootState.preferences.timezone)
    return response.data
  },
  async createSuspension ({ commit, rootState }, suspension) {
    const response = await axios.post('suspensions', suspension)
    applyLocalTime(response.data, rootState.preferences.timezone)
    return response.data
  },
  async updateSuspension ({ commit, rootState }, suspension) {
    const response = await axios.put('suspensions', suspension)
    applyLocalTime(response.data, rootState.preferences.timezone)
    return response.data
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
