import Vue from 'vue'
import VueI18n from 'vue-i18n'
import englishMessages from '@/locales/en'

Vue.use(VueI18n)

const loadedLanguages = ['en']

export const languages = [
  {
    code: 'en',
    flag: 'gb',
    displayName: 'English',
    searchTags: ['english', 'en', 'gb', 'us']
  },
  {
    code: 'es',
    flag: 'es',
    displayName: 'Español',
    searchTags: ['español', 'espanol', 'es', 'latin', 'spanish']
  },
  {
    code: 'nl',
    flag: 'nl',
    displayName: 'Nederlands',
    searchTags: ['nederlands', 'nl', 'be', 'dutch']
  }
]

// It would be better to load the default the user's preferred locale,
// but that would only be possible if the setLanguageAsync method could be awaited in this file.
// Therefore, the locale english is loaded always by default.
export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en: englishMessages }
})

/**
 * Checks if a specific language code is supported by the webapp.
 * @param {String} languageCode The language code to check.
 * @returns True if supported, otherwise false.
 */
export function languageIsSupported (languageCode) {
  // Any falsy value (null, undefined, empty string, etc.) should immediately return false.
  return languageCode ? languages.some((l) => l.code === languageCode) : false
}

/**
 * Loads a specific i18n code into i18n.
 * @param {String} languageCode The code of the language to load.
 */
export async function loadLanguageAsync (languageCode) {
  if (!languageIsSupported(languageCode)) throw new Error(`Language code "${languageCode}" is not supported`)
  if (loadedLanguages.includes(languageCode)) return

  loadedLanguages.push(languageCode)
  // The language file path must not use interpolation (`@/locales/{newLanguageCode}`), because otherwise eslint will complain.
  // This should be fixed in future releases: https://github.com/babel/babel-eslint/issues/815#issuecomment-634499697.
  const messages = await import(/* webpackChunkName: "lang-[request]" */ '@/locales/' + languageCode)
  i18n.setLocaleMessage(languageCode, messages.default)
}

/**
 * Sets the new language code for the webapp.
 * This will only apply to the custom translations; individual components like vuetify will be updated in the 'store/languages.js' file.
 * @param {String} languageCode The code of the new language.
 */
export async function setLanguageAsync (languageCode) {
  await loadLanguageAsync(languageCode)
  i18n.locale = languageCode
}
