import axios from 'axios'

const state = {
  userGroups: []
}

const getters = {
  getUserGroupsByIds: (state) => (ids) => {
    return state.userGroups.filter((group) => { return ids.includes(group.id) })
  }
}

const actions = {
  /**
   * Fetches all user groups. This should only be called for admin screens.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Object} The (axios) response from the server.
   */
  async fetchAllUserGroups ({ commit }) {
    const response = await axios.get('userGroups/unpaged')
    commit('setUserGroups', response.data)
    return response
  },
  /**
   * Fetches usergroups sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchUserGroups ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('usergroups', { params: { pageNumber: pageNumber, pageSize: pageSize, sorting: sorting.toQueryParams() } })
    return response.data
  }
}

const mutations = {
  setUserGroups: (state, userGroups) => (state.userGroups = userGroups)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
