import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
// NOTE: it's probably not that pretty to import all languages, but they are very small files.
import apexnl from 'apexcharts/dist/locales/nl.json'
import apexen from 'apexcharts/dist/locales/en.json'
import apexes from 'apexcharts/dist/locales/es.json'

Vue.component('apexchart', VueApexCharts)

// Setup locales for charts.
window.Apex.chart = {
  locales: [apexnl, apexen, apexes],
  defaultLocale: 'en'
}
