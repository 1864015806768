export default class FuelType {
  /**
  * Creates a fuel type instance
  * @param {string} name The name of the fuel type.
  * @param {number} value The value of the the fuel type, which is used for communication with the api.
  * @param {string} translationKey The key to translate the fuel type into something readable for the user.
  * @param {Array<Unit>} units The available units for this fuel type.
  */
  constructor (name, value, translationKey, units) {
    this.name = name
    this.value = value
    this.translationKey = translationKey
    this.units = units
  }

  /**
   * Gets the unit with the provided name.
   * @param {string} name The name of the unit to retrieve.
   * @returns The unit if found, otherwise null.
   */
  getUnitByName (name) {
    return this.units && this.units.length > 0
      ? this.units.find(unit => unit.name === name)
      : null
  }
}
