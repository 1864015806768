import axios from 'axios'
import Sorting from '@/models/sorting'

const state = {
  fileContainers: []
}

const getters = {
  getFileContainerById: (state) => (id) => {
    return state.fileContainers.find((container) => container.id === id)
  }
}

const actions = {
  /**
   * Fetches file containers sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchFileContainers ({ commit }, { pageNumber, pageSize, sorting }) {
    const response = await axios.get('fileContainers', {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        sorting: sorting.toQueryParams()
      }
    })
    return response.data
  },
  /**
   * Fetches all file containers.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Array} The file containers.
   */
  async fetchAllFileContainers ({ commit }) {
    // Sort by display name ascending.
    const sorting = new Sorting(['displayName'], [false])
    const response = await axios.get('fileContainers', {
      params: {
        sorting: sorting.toQueryParams()
      }
    })
    commit('setFileContainers', response.data.items)
    return response.data.items
  }
}

const mutations = {
  setFileContainers: (state, fileContainers) => (state.fileContainers = fileContainers)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
