import axios from 'axios'

const state = {
  drivers: []
}

const getters = {
  driverIds: (state) => state.drivers.map(s => s.id),
  getDriverById: (state) => (id) => {
    return state.drivers.find((driver) => { return driver.id === id })
  }
}

const actions = {
  /**
   * Fetches all drivers the current user has access to.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Promise} The response of the server.
   */
  fetchDrivers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('drivers')
        .then(response => {
          commit('setDrivers', response.data.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * Fetches all drivers that fuelled at a specific system.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {*} systemId The id of the system to fetch drivers for.
   * @returns {Object} The axios response.
   */
  async fetchDriversBySystem ({ commit }, systemId) {
    const response = await axios.get(`systems/${systemId}/drivers`)
    commit('addOrUpdateDrivers', response.data.items)
    // Returning response for situations where the caller would like to specifically display/use the drivers for this system.
    return response
  }
}

const mutations = {
  setDrivers: (state, drivers) => (state.drivers = drivers),
  addOrUpdateDrivers: (state, newDrivers) => {
    if (state.drivers.length === 0) {
      state.drivers = newDrivers
      return
    }

    for (let i = 0; i < newDrivers.length; i++) {
      const existingIndex = state.drivers.findIndex((existingDriver) => existingDriver.id === newDrivers[i].id)
      if (existingIndex >= 0) {
        // Replace the existing driver.
        state.drivers[existingIndex] = newDrivers[i]
      } else {
        // Add the driver.
        state.drivers.push(newDrivers[i])
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
