import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { ErrorEnum } from '@/enums'
import {
  getTimeUntilExpiryFromDecodedToken,
  getRolesFromDecodedToken,
  getUserGroupsFromDecodedToken
} from '@/components/helpers/jwtHelper'

const AUTH_TOKEN_KEY = 'authentication_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

const state = {
  authenticationToken: localStorage.getItem(AUTH_TOKEN_KEY),
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  decodedToken: localStorage.getItem(AUTH_TOKEN_KEY) ? jwtDecode(localStorage.getItem(AUTH_TOKEN_KEY)) : null,
  // The errors from the most recent authentication request.
  authenticationErrors: [],
  // Indicates whether the web app is currently busy attempting to authenticate or refreshing an authentication.
  isBusyAuthenticating: false,
  // The user group objects this user is authorized to see.
  authorizedUserGroups: []
}

const getters = {
  /**
   * The date at which the current authentication token will expire.
   * @returns {number|null} A unix timestamp (ms) if there is an authentication token, otherwise null.
   */
  tokenExpiryDate: (state) => {
    return state.decodedToken ? state.decodedToken.exp : null
  },
  /**
   * All user groups the current user has access to.
   * @returns {Array<number>} An array of ids of user groups.
   */
  authorizedUserGroupIds: (state) => {
    return getUserGroupsFromDecodedToken(state.decodedToken)
  },
  roles: (state) => {
    return getRolesFromDecodedToken(state.decodedToken)
  },
  /**
   * Gets the email address of the current user.
   * @returns {String|null} An email address if the user is logged in, otherwise null.
   */
  emailAddress: (state) => {
    return state.decodedToken ? state.decodedToken.email : null
  },
  /**
   * Indicates whether the current user is authenticated.
   * This getter is not cached and should be called with parentheses isAuthenticated().
   * Vuex doesn't understand that this getter uses the current time, therefore we make sure it is not cached by making it a function.
   * @returns {boolean} True if authenticated, otherwise false.
   */
  isAuthenticated: (state) => () => {
    if (!state.decodedToken) return false

    const msUntilExpiry = getTimeUntilExpiryFromDecodedToken(state.decodedToken)
    const secondsUntilExpiry = Math.ceil(msUntilExpiry / 1000)
    if (secondsUntilExpiry <= 0) {
      console.log(`User is not authenticated (${Math.abs(secondsUntilExpiry)} seconds overdue)`)
    } else {
      console.log(`User is authenticated (${secondsUntilExpiry} seconds left)`)
    }

    return secondsUntilExpiry > 0
  },
  requiresRefresh: (state, getters) => () => {
    return state.authenticationToken && !getters.isAuthenticated() && !state.isBusyAuthenticating
  },
  hasSavedAuthenticationData: (state) => {
    return state.authenticationToken != null && state.authenticationToken !== undefined
  },
  isEmployee: (state, getters) => {
    return getters.roles.includes('Employee') || getters.isAdmin
  },
  isAdmin: (state, getters) => {
    return getters.roles.includes('Admin') || getters.isOverlord
  },
  isOverlord: (state, getters) => {
    return getters.roles.includes('Overlord')
  },
  canUploadSessions: (state) => {
    return state.decodedToken.sessionsUploader && state.decodedToken.sessionsUploader === 'true'
  },
  /**
   * Gets the id of the current user.
   * @returns {number|null} The id of the user, or null if the user is not logged in.
   */
  id: (state) => {
    return state.decodedToken ? state.decodedToken.id : null
  }
}

const actions = {
  /**
   * Attempts to retrieve an authentication token for the specified email/password combination.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} user The user information required for obtaining a token.
   * @param {string} user.emailAddress The emailAddress of the user.
   * @param {string} user.password The password of the user.
   */
  async obtainToken ({ commit }, { emailAddress, password }) {
    commit('setIsBusyAuthenticating', true)
    commit('setErrors', [])

    const payload = {
      emailAddress: emailAddress,
      password: password
    }

    try {
      const response = await axios.post('/account/login', payload)
      commit('updateTokens', { authToken: response.data.token, refreshToken: response.data.refreshToken })
    } catch (error) {
      if (!error.response) {
        commit('setErrors', [ErrorEnum.LOGIN_UNKNOWN_FAILURE])
        throw error
      }

      if (error.response.status === 401) {
        // When a the login attempt is rejected (401), the server should've sent custom error messages.
        commit('setErrors', error.response.data.errors)
      } else if (error.response.status === 429) {
        // When too many login attempts are done in a short period (429), the server should've sent an error.
        commit('setErrors', [ErrorEnum.TOO_MANY_REQUESTS])
      } else {
        // An unexpected error occurred, this must indicate a bug or the server is offline.
        commit('setErrors', [ErrorEnum.LOGIN_UNKNOWN_FAILURE])
      }
      throw error
    } finally {
      commit('setIsBusyAuthenticating', false)
    }
  },
  /**
   * Attempts to refresh the authentication token for the current user.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   */
  async refreshLogin ({ commit, state }) {
    commit('setIsBusyAuthenticating', true)
    commit('setErrors', [])

    const payload = {
      token: state.authenticationToken,
      refreshToken: state.refreshToken
    }

    try {
      // use private variable to keep 1 active JWT refresh request at any time.
      this.refreshTokenPromise = this.refreshTokenPromise || axios.post('/account/refreshLogin', payload)
      const response = await this.refreshTokenPromise
      commit('updateTokens', { authToken: response.data.token, refreshToken: response.data.refreshToken })
    } catch (error) {
      // When a the refresh login attempt is rejected (400), the server should've sent custom error messages.
      if (error.response && error.response.status === 400) {
        commit('setErrors', error.response.data.errors)
        commit('removeTokens')
      } else {
        commit('setErrors', [ErrorEnum.REFRESH_LOGIN_UNKNOWN_FAILURE])
      }

      throw error
    } finally {
      this.refreshTokenPromise = null
      commit('setIsBusyAuthenticating', false)
    }
  },
  /**
   * Attempts to change the password of the current user.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   */
  async changeMyPassword ({ commit }, { currentPassword, newPassword, repeatNewPassword }) {
    commit('setIsBusyAuthenticating', true)
    commit('setErrors', [])

    const payload = {
      oldPassword: currentPassword,
      newPassword: newPassword,
      repeatNewPassword: repeatNewPassword
    }

    try {
      const response = await axios.post('/account/changePassword', payload)
      commit('updateTokens', { authToken: response.data.token, refreshToken: response.data.refreshToken })
    } catch (error) {
      if (!error.response) {
        commit('setErrors', [ErrorEnum.PASSWORD_CHANGE_UNKNOWN_FAILURE])
        throw error
      }

      if (error.response.status === 400 && Array.isArray(error.response.data.errors)) {
        // When a the change password attempt is rejected (400), the server should've sent custom error messages.
        commit('setErrors', error.response.data.errors)
      } else if (error.response.status === 429) {
        // When too many change password attempts are done in a short period (429), the server should've sent an error.
        commit('setErrors', [ErrorEnum.TOO_MANY_REQUESTS])
      } else {
        // An unexpected error occurred, this must indicate a bug or the server is offline.
        commit('setErrors', [ErrorEnum.PASSWORD_CHANGE_UNKNOWN_FAILURE])
      }
      throw error
    } finally {
      commit('setIsBusyAuthenticating', false)
    }
  },
  /**
   * Retrieves all user groups that the current user has access to.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   */
  async fetchMyUserGroups ({ commit }) {
    try {
      const response = await axios.get('/account/myUserGroups')
      commit('setAuthorizedUserGroups', response.data)
    } catch (error) {
      commit('addError', ErrorEnum.LOADING_USERGROUPS_FAILED)
      throw error
    }
  },
  /**
   * Removes all stored auth data:
   * - No longer sending the authentiction token to the server with every request.
   * - Removing existing tokens from the local storage.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   */
  removeAuthData ({ commit }) {
    // Remove the tokens from the storage, this will tell the webapp that the user is no longer authenticated.
    commit('removeTokens')
  },
  /**
   * Logs the the user out of the web application by:
   * - Removing all stored auth data.
   * - Telling the server to log out the current user.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @returns {Promise} Response of the server.
   */
  logout ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setIsBusyAuthenticating', true)

      // Send a logout request to the server, which should erase all refresh tokens for the user.
      axios
        .delete('/account/logout')
        .then(response => {
          dispatch('removeAuthData')
          return resolve(response)
        })
        .catch(error => {
          return reject(error)
        })
        .finally(() => {
          commit('setIsBusyAuthenticating', false)
          console.log('User was logged out')
        })
    })
  }
}

const mutations = {
  updateTokens: (state, { authToken, refreshToken }) => {
    localStorage.setItem(AUTH_TOKEN_KEY, authToken)
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    state.authenticationToken = authToken
    state.refreshToken = refreshToken
    state.decodedToken = jwtDecode(authToken)

    // If there is an authentication token available (it should, since we just set it)...
    if (state.authenticationToken) {
      // Make sure the token is sent with every future request.
      axios.defaults.headers.Authorization = `Bearer ${state.authenticationToken}`
    }
  },
  removeTokens: (state) => {
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    state.authenticationToken = null
    state.refreshToken = null
    state.decodedToken = null

    // No longer send an auth token with every request.
    delete axios.defaults.headers.common.Authorization
  },
  setAuthorizedUserGroups: (state, userGroups) => {
    state.authorizedUserGroups = userGroups
  },
  setErrors: (state, errors) => {
    state.authenticationErrors = errors
  },
  addError: (state, error) => {
    state.authenticationErrors.push(error)
  },
  setIsBusyAuthenticating: (state, isBusy) => {
    state.isBusyAuthenticating = isBusy
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
