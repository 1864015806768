import FuelType from '@/models/fuelType'
import Unit from '@/models/unit'

const diesel = new FuelType('diesel', 10, 'common.diesel', [
  new Unit('liters', 'units.liters', 'units.liters-short', 0, undefined),
  new Unit('gallons-us', 'units.gallons-us', 'units.gallons-us-short', 0, x => x * 0.264172),
  new Unit('gallons-uk', 'units.gallons-uk', 'units.gallons-uk-short', 0, x => x * 0.219969)
])
const lng = new FuelType('lng', 20, 'common.lng', [
  // Just speculating here, we don't know what units will be used for LNG.
  new Unit('kilograms', 'units.kilograms', 'units.kilograms-short', 0, undefined)
])
const power = new FuelType('power', 30, 'common.power', [
  new Unit('kilowatt', 'units.kilowatt', 'units.kilowatt-short', 0, undefined),
  new Unit('megawatt', 'units.megawatt', 'units.megawatt-short', 2, x => x * 0.001)
])

const state = {
  dieselUnitPreference: localStorage.getItem('dieselUnit') || 'liters',
  lngUnitPreference: localStorage.getItem('lngUnit') || 'kilograms',
  powerUnitPreference: localStorage.getItem('powerUnit') || 'kilowatt',
  fuelTypes: [
    diesel,
    lng,
    power
  ]
}

const getters = {
  getFuelTypeByName: (state) => (name) => {
    return state.fuelTypes.find(f => f.name === name)
  },
  getFuelTypeByValue: (state) => (value) => {
    return state.fuelTypes.find(f => f.value === value)
  },
  getPreferredUnitByFuelType: (state) => (fuelType) => {
    return fuelType.getUnitByName(state[`${fuelType.name}UnitPreference`])
  }
}

const actions = {
  changeUnitPreference ({ commit }, { fuelType, unit }) {
    localStorage.setItem(`${fuelType.name}Unit`, unit.name)
    commit('setUnitPreference', { fuelTypeName: fuelType.name, unitName: unit.name })
  }
}

const mutations = {
  setUnitPreference: (state, { fuelTypeName, unitName }) => (state[`${fuelTypeName}UnitPreference`] = unitName)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
