const state = {
  show: false,
  params: {
    role: 'info',
    duration: -1,
    messages: [],
    showCloseButton: false
  }
}

const getters = {}

const actions = {
  /**
   * Shows a snackbar with the provided data.
   * @param {*} param0
   * @param {String} params.role
   * @param {Array} params.messages
   * @param {Number} params.duration
   * @param {Boolean} params.showCloseButton
   */
  showSnackbar ({ commit }, { role, messages, duration, showCloseButton }) {
    commit('setShow', false)
    commit('setParams', {
      role: role || 'info',
      messages: messages || [],
      duration: duration === undefined || duration < -1 ? -1 : duration,
      showCloseButton: showCloseButton || true
    })
    commit('setShow', true)
  },
  /**
   * Hides the currently shown snackbar.
   */
  hideSnackbar ({ commit }) {
    commit('setShow', false)
  }
}

const mutations = {
  setShow: (state, show) => (state.show = show),
  setParams: (state, params) => (state.params = params)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
