<template>
  <v-app>
    <Navigation v-if="$vuetify.breakpoint.mdAndUp && !prefersBottomNavigation"/>

    <v-main>
      <v-container fluid>
        <GlobalSnackBar/>
        <router-view app></router-view>
      </v-container>
    </v-main>

    <BottomNavigation v-if="!$vuetify.breakpoint.mdAndUp || prefersBottomNavigation"/>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import axiosSetup from '@/axiosSetup'
import BottomNavigation from '@/components/BottomNavigation'
import GlobalSnackBar from '@/components/GlobalSnackBar'
import Navigation from '@/components/Navigation'

export default {
  name: 'App',
  components: { BottomNavigation, GlobalSnackBar, Navigation },
  watch: {
    // Watches for route changes.
    $route: {
      immediate: true,
      handler (to, from) {
        // Update the browser title.
        document.title = to.meta.title ? to.meta.title + ' - Rotec Dashboard' : 'Rotec Dashboard'
        // Hide the currently displayed (global) snackbar.
        this.hideSnackbar()
      }
    }
  },
  computed: {
    ...mapState('languages', ['currentLanguageCode']),
    ...mapState('preferences', ['prefersBottomNavigation', 'timezone']),
    ...mapGetters('account', ['roles']),
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions('snackbar', ['hideSnackbar']),
    ...mapActions('menuItems', ['updateMenuItemsToRoles']),
    ...mapActions('languages', ['changeLanguage']),
    ...mapActions('preferences', ['changeTimezone'])
  },
  async mounted () {
    // We have to setup axios as soon as possible
    // to make sure the axios base url is set before any components use axios in their mounted hook.
    axiosSetup.setup(this.$store, this.$router)
    await this.changeLanguage({ newLanguageCode: this.currentLanguageCode, vuetify: this.$vuetify })
    this.changeTimezone(this.timezone)

    this.updateMenuItemsToRoles(this.roles)
  }
}
</script>

<style lang="scss">
// Vuetify always shows a scrollbar by default, but we only want to do that when there is actually content to scroll.
html {
  overflow-y: auto
}

// By default vuetify breaks on characters instead of words. We want vuetify to break only on full words.
// For more info see https://github.com/vuetifyjs/vuetify/issues/9130.
.v-card__text, .v-card__title {
  word-break: normal !important;
}

// Apex charts always have backgrounds, which is not necessary.
svg.apexcharts-svg { background: transparent !important; }
</style>
