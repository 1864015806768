<template>
  <div>
    <ChangeLanguageDialog
      v-model="showLanguageDialog"
      @input="newValue => { showLanguageDialog = newValue }"
    />
    <v-navigation-drawer
      v-model="$vuetify.breakpoint.mdAndUp"
      expand-on-hover
      mini-variant
      app
      stateless
      class="drawer"
    >
      <!-- Link to user settings -->
      <v-list v-if="isLoggedIn">
        <v-list-item :to="'/settings'" active-class="">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ emailAddress }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider v-if="isLoggedIn"></v-divider>

      <!-- Customer/visitor links -->
      <v-list nav dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="showLanguageDialog = true">
          <v-list-item-icon>
            <gb-flag :code="currentLanguage.flag" width="24px" height="24px"/>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('common.language') }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- Admin links -->
      <v-list v-if="isAdmin" nav dense>
        <v-list-item
          v-for="item in adminMenuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider v-if="isAdmin"></v-divider>

      <v-list>
        <v-list-item class="px-1">
          <v-img contain src="@/assets/rotec-logo.png"></v-img>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ChangeLanguageDialog from '@/components/dialogs/ChangeLanguageDialog'

export default {
  components: { ChangeLanguageDialog },
  data () {
    return {
      value: this.$route.name,
      showLanguageDialog: false
    }
  },
  computed: {
    ...mapGetters('languages', ['currentLanguage']),
    ...mapState('menuItems', ['menuItems', 'adminMenuItems']),
    ...mapGetters('account', ['roles', 'emailAddress', 'isAdmin']),
    isLoggedIn () {
      return this.roles.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
// .drawer {
//   max-width: 12rem;
// }
</style>
