export default class Unit {
  /**
   * Creates a unit instance.
   * @param {string} name The name of the unit.
   * @param {string} longTranslationKey The translation key for the long display name of the unit.
   * @param {string} shortTranslationKey The translation key for the short display name of the unit, usually an abbreviation.
   * @param {function(amount)} converter The converter to convert from the base unit to this unit.
   */
  constructor (name, longTranslationKey, shortTranslationKey, displayPrecision, converter) {
    this.name = name
    this.longTranslationKey = longTranslationKey
    this.shortTranslationKey = shortTranslationKey
    this.displayPrecision = displayPrecision
    this.converter = converter
  }

  /**
   * Converts the specified base unit amount to this specific unit amount.
   * @param {number} amount The amount in the base unit (liter for diesel, kw for power, etc.) to convert.
   * @returns The converted amount in this unit.
   */
  convert (amount) {
    return this.converter ? this.converter(amount) : amount
  }
}
