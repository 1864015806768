import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: false,
      title: 'About'
    }
  },
  {
    path: '/register/:registrationKey',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      requiresAuth: false,
      title: 'Register'
    }
  },
  {
    path: '/reset-password/:passwordResetKey',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
      title: 'Reset password'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
      title: 'Forgot password'
    }
  },
  {
    path: '/systems',
    name: 'SystemsOverview',
    component: () => import(/* webpackChunkName: "systemsoverview" */ '../views/SystemsOverview.vue'),
    meta: {
      requiresAuth: true,
      title: 'Systems overview'
    }
  },
  {
    path: '/systems/:systemId',
    name: 'SystemDetails',
    component: () => import(/* webpackChunkName: "systemdetails" */ '../views/SystemDetails.vue'),
    meta: {
      requiresAuth: true,
      title: 'System details'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: false,
      title: 'Settings'
    }
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/Sessions.vue'),
    meta: {
      requiresAuth: true,
      title: 'Sessions'
    }
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    component: () => import(/* webpackChunkName: "vehicles" */ '../views/Vehicles.vue'),
    meta: {
      requiresAuth: true,
      title: 'Vehicles'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'Users'
    }
  },
  {
    path: '/admin/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'Projects'
    }
  },
  {
    path: '/admin/systems',
    name: 'Systems',
    component: () => import(/* webpackChunkName: "systems" */ '../views/Systems.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'Systems'
    }
  },
  {
    path: '/admin/usergroups',
    name: 'UserGroups',
    component: () => import(/* webpackChunkName: "usergroups" */ '../views/UserGroups.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'User groups'
    }
  },
  {
    path: '/admin/errorcauses',
    name: 'ErrorCauses',
    component: () => import(/* webpackChunkName: "errorcauses" */ '../views/ErrorCauses.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'Error causes'
    }
  },
  {
    path: '/admin/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "files" */ '../views/Files.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'Files'
    }
  },
  {
    path: '/admin/filecontainers',
    name: 'FileContainers',
    component: () => import(/* webpackChunkName: "filecontainers" */ '../views/FileContainers.vue'),
    meta: {
      requiresAuth: true,
      satisfiesRoleRequirements: (roles) => roles.includes('Admin') || roles.includes('Overlord'),
      title: 'File containers'
    }
  },
  // Finally the default route, when none of the above matches:
  {
    path: '*',
    name: 'Page not found',
    component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/special/PageNotFound.vue'),
    meta: {
      requiresAuth: false,
      title: '404 - Not Found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Multiple routes matching isn't really a thing, so we just pick the first one.
  const route = to.matched[0]
  const requiresAuth = route.meta.requiresAuth
  const requiresRole = route.meta.satisfiesRoleRequirements

  // If this route does not require authentication or authorization...
  if (!requiresAuth && !requiresRole) {
    // Navigate to the desired route.
    next()
    return
  }

  const roles = store.getters['account/roles']

  // Check if the user is authenticated if required.
  if (requiresAuth) {
    // We use roles to check if the user is authenticated,
    // because the 'account/isAuthenticated' can return false when a token refresh has not been issued yet.
    const isAuthenticated = roles && roles.length > 0
    if (!isAuthenticated) {
      next({ name: 'Login' })
      return
    }
  }

  // Check if the user satisfies the role requirements if required.
  if (requiresRole) {
    const isInRequiredRole = route.meta.satisfiesRoleRequirements(roles)
    if (!isInRequiredRole) {
      next({ name: 'Login' })
      return
    }
  }

  // If we get here, the user satisfies all the requirements to go to the new route.
  next()
})

export default router
