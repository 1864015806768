import axios from 'axios'

const state = {}

const getters = {}

const actions = {
  /**
   * Fetches sessions sorted and paginated.
   * @param {*} param0 vuex object, does not have to be provided by the consumer.
   * @param {Object} params
   * @param {Number} params.pageNumber
   * @param {Number} params.pageSize
   * @param {Object} params.filter
   * @param {Sorting} params.sorting
   * @returns {Object} The paged response.
   */
  async fetchSessions ({ commit }, { pageNumber, pageSize, filter, sorting }) {
    // With post requests, query params can't be specified in the url, so they have to be in the body.
    const response = await axios
      .post('sessions/filtered', {
        filter: filter,
        pagination: { pageNumber: pageNumber, pageSize: pageSize },
        sorting: sorting.toQueryParams()
      })
    return response.data
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
